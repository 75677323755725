import React, { Component } from 'react';
import './timelinemain.css';

export default class TimelineVJ extends Component {
  render() {
    return (
      <div className="timelineCSS">
<h1 className='text-dark-main'>VJ TOP STORIES</h1>
<ul>
    <li style={{"--accent-color":"#41516C"}}>
        <div class="date">5 - 6 JANUARY, 2024</div>
        <div class="title">PRE-POLL SURVEY REPORT</div>
        <div class="descr">The renowned Apt Research Group (ARG), known for its accurate election forecasting in South India since 1989, collaborated with Vidyajyoti, Institute of Religious Studies, to conduct a pre-poll survey in Delhi. This report analyses the survey process and findings, based on a small sample size of 5000 respondents, showcasing ARG's expertise in data collection and political trend analysis. Led by Ms. Jothi R, ARG's Vice President, the survey was conducted on Jan 5th and Jan 6th, 2024, by 16 students of Vidyajyoti at 8 locations in Delhi. The questionnaire was in Hindi, focusing on individuals aged 18 and above, with a target of 27 interviews per day per interviewer. Participating in the survey provided valuable insights into public sentiment towards the upcoming Lok Sabha election, offering firsthand experience in field data collection. The diverse responses shed light on people's perceptions of national development and political parties. These findings will guide ARG in understanding local needs and making informed decisions for the upcoming elections.</div>
    </li>
    <li style={{"--accent-color":"#FBCA3E"}}>
        <div class="date">16 JANUARY, 2024</div>
        <div class="title">PONGAL & BIHU CELEBRATION</div>
        <div class="descr">In India, various festivals mark the beginning of the harvest season and the transition of the Sun to Capricorn, symbolizing warmer days. Makar Sankranti, Uttrayan, Dahi Suda, Bihu, Lohri, and Pongal are celebrated with joy around this time. On January 16, 2024, Pongal and Bihu were celebrated at VJ during the tea-break. Sch. Ronald highlighted Pongal's significance, encompassing agricultural, religious, cultural, and social aspects. Pongal spans four days: Bhogi for starting anew, Thai Pongal for cooking and offering the Pongal dish to the Sun God, Mattu Pongal for honouring cattle, and Kaanum Pongal for family gatherings. Sr. Sheela emphasized sweetness, symbolizing sowing, nurturing, and harvesting, reflecting God's goodness. Sch. Wilson then explained about Bihu. The Tamil Brothers sang a traditional Pongal song, and for Bihu everybody danced together. Everyone shared Pongal and sugarcane to celebrate. Indeed, it was a typical VJ kind of a Pongal and Bihu celebration.</div>
    </li>
    <li style={{"--accent-color":"#E24A68"}}>
        <div class="date">26 JANUARY, 2024</div>
        <div class="title">REPUBLIC DAY EXTENSION LECTURE</div>
        <div class="descr">On the eve of Republic Day, Vidyajyoti hosted an extension lecture titled "Understanding Indian Culture Through and For Christ," featuring P.I. Jose, an Advocate on Record in the Supreme Court, as the speaker. Sch. Julius Tudu SJ introduced the theme and the speaker. Advocate Jose began by highlighting the uniqueness of the upcoming 2024 election, attributing it to the growing power of Hindutva supremacists, which poses a threat to India's democracy. He discussed culture, defining it as societal cultivation, and pointed out the polarization and weakening of democratic foundations caused by Hindutva forces. Referring to a 1996 Supreme Court verdict, he explained how Hindutva was legalized as synonymous with Indianization, enabling ultra-nationalist politics and religious-based vote solicitation by parties like BJP and Shiv Sena. He contrasted Hindutva's push for uniform culture with the Constitution's emphasis on cultural diversity, citing Article 29's protection of cultural preservation rights. He advocated for coexistence and mutual identification, drawing from Buddha's teachings and emphasizing Christ's boundless love as the ultimate cultural ethos.
</div>
    </li>
    <li style={{"--accent-color":"#1a9d04"}}>
        <div class="date">30 JANUARY, 2024</div>
        <div class="title">ETHICS & PASTORAL CHALLENGES: ARTIFICIAL INTELLIGENCE AND CHURCH</div>
        <div class="descr">On January 30th, 2024, Vidyajyoti College hosted a thought-provoking extension lecture addressing "Ethics and Pastoral Challenges at the End of Life" and "Artificial Intelligence and Church: A Great Opportunity." The esteemed speakers were Rev. Archbishop Vincenzo Paglia, President of the Pontifical Academy of Life and recipient of the Gandhi Award for Peace and the Mother Teresa Albania Award, and Fr. Andrea Ciucci, Coordinator for the Pontifical Academy of Life.
Rev. Archbishop Paglia commenced the seminar by emphasizing the contemporary imperative of dialogue as a foundational pillar for addressing societal challenges. He highlighted India's concerning meagre budget for health, advocating for compassionate care towards the weak and sick. Focusing on palliative care, especially for the elderly, he stressed the need for accompaniment and hospice beyond mere medical treatment. The archbishop underscored the Catholic Church's commitment to promoting human dignity and palliative care, advocating for community-based support and solidarity in the face of demographic shifts and societal changes. Fr. Andrea Ciucci delved into the topic of artificial intelligence, likening it to a 2.0 version of the Copernican revolution with profound implications for society. He cautioned against the inclination to treat technology as a new religion and emphasized the Church's responsibility to engage with scientists in addressing the social dimensions of AI. Fr. Ciucci highlighted the cultural impact of AI, expressing concerns over its potential to replace human culture and alter social structures. He emphasized the need to focus on the social dimension of AI and questioned the disproportionate emphasis on the soul over the body in discussions surrounding this technology.
Throughout the seminar, participants engaged in discussions around the ethical challenges posed by AI and the imperative of compassionate care at the end of life. The seminar concluded with a call to action for collaboration between religious institutions, academia, and policymakers to navigate the ethical complexities of the modern world while upholding timeless values of compassion, dignity, and solidarity.
</div>
    </li>
    <li style={{"--accent-color":"#1B5F8C"}}>
        <div class="date">31 JAN - 02 FEB, 2024</div>
        <div class="title">NATIONAL CONFERENCE ON MISSION 2024</div>
        <div class="descr">The 5th Jubilee 14th National Conference on Mission 2024 was organized at Dharma Jyoti Vidyapeeth Marthoma Seminary, Faridabad from 31st January to 2nd of February. Around 65 people participated in the national conference representing thirteen church denominations and three organisations. Vidyajyoti students Vidyasagar and Sooraj attended the seminar. The central focus of the conference was on the commitment to the intersectional approach to mission in India. The paper presenters of the conference recognized and affirmed the contributions of the missionaries in the past and called Christians to carry forward the same commitment towards the tribals, Dalits, minorities, refugees, women, children, persons with disabilities, the elderly, and all marginalized communities. Many acknowledged that the current socio-political situation in India is one of the constraints of the Christian mission in the way it has been carried out. We all need to commit to a continuous process of metanoia recognizing the power of micro-narratives and listening to the diverse voices within our society. We need to commit to a mutually transformative mission, leaving no one behind.
Fr. Edwin Rodriguez SJ presented a paper titled “The Catholic Mission Perspectives: Challenges, Critique, and New Paradigms.” His presentation offered valuable insights and perspectives on the Catholic mission, sparking a lively discussion among the participants from different Christian traditions.
</div>
    </li>
    <li style={{"--accent-color":"#4CADAD"}}>
        <div class="date">23 FERBRUARY, 2024</div>
        <div class="title">VICTOR COURTOIS MEMORIAL LECTURE</div>
        <div class="descr">The 7th Victor Courtois Memorial lecture, organized by the Islamic Studies Association Delhi on Feb 23, 2024, at Millennium Hall, Saint Xavier's School Delhi, commemorated Fr. Victor Courtois, a Belgian Jesuit renowned for his contributions to Christian-Muslim relations in India. Fr. Courtois's approach emphasized love and respect towards Muslims, fostering deep understanding and dialogue. The lecture featured Fr. Michael Calabria OFM, a leading scholar in Christian-Muslim dialogue, discussing "From Morocco to the Mughal Empire: Franciscan Encounters with Muslims and their Significance for Interreligious Dialogue Today." Fr. Calabria highlighted Saint Francis of Assisi's respectful engagement with Muslims during the 5th Crusade, contrasting it with the Crusaders' hostility. He emphasized the enduring lesson of Assisi: meekness, humility, and service, echoed by St. John Paul II and Vatican II. Fr. Calabria urged Christians to follow Francis's example, promoting collaboration with Muslims for justice and peace. He also touched on historical instances of Christian-Muslim dialogue, including efforts in Akbar's court.</div>
    </li>
    <li style={{"--accent-color":"#f80527"}}>
        <div class="date">27 FERBRUARY, 2024</div>
        <div class="title">INTERNATIONAL SEMINAR IRENIC 2024
"Peace and Reconciliation: A Journey Towards Reign of God,"
</div>
        <div class="descr">Irenic Seminar: The three-day international seminar, "Peace and Reconciliation: A Journey Towards Reign of God," commenced on February 27, 2024, at Millennium Hall, Saint Xavier's School Delhi. It emphasized the urgency of fostering peace and reconciliation within oneself, among others, and in the world. The seminar aimed to explore various life situations, biblical foundations, challenges, commonalities, and best practices in peace and reconciliation. The participation of experts, scholars, theologians from India and abroad, along with students, enriched the seminar.
        <br/>
        Day 1: Theological and Sociological Perspectives
        <br/>
        Day 2: International and Interdisciplinary Perspectives
        <br/>
        Day 3: Best Practices in Reconciliation and Peacebuilding </div>
    </li>
    <li style={{"--accent-color":"#4CADAD"}}>
        <div class="date">08 MARCH, 2024</div>
        <div class="title">INTERNATIONAL WOMEN’S DAY CELEBRATION</div>
        <div class="descr">On the occasion of International Women’s Day, 8th March 2024, an extension lecture was organized to commemorate the significance of the day and to shed light on the challenges and achievements of women globally. The event was graced by esteemed speaker and participants, contributing to a meaningful discussion on the empowerment of women and gender equality. Dr. Ella Sonawane, the Associate General Secretary of ISPCK, Delhi, served as the spokesperson for International Women’s Day. Dr. Ella, a strong advocate for women’s rights and empowerment, shared her insights and experiences on the occasion. The principal, Fr. Rajkumar introduced Dr. Ella, highlighting her remarkable achievements as a strong woman, author, speaker, and social service advocate. On this special occasion all the sisters present were felicitated with a bouquet, symbolizing appreciation and recognition for their roles and contributions.
Dr. Ella shared her insights into the roles and challenges faced by women in society, drawing attention to patriarchal norms, bias, and the lack of political and economic representation. And also Dr. Ella cited examples from Uttar Pradesh and Punjab where empowered women have made significant progress, enabling them to voice their concerns and participate actively in society. The theme of inclusion resonated throughout the discussion, with emphasis placed on encouraging a culture where women are respected and empowered. Dr. Ella stressed the importance of men embracing feminism and supporting gender equality initiatives, emphasizing that the empowerment of women is crucial for societal development. So, the International Women’s Day extension lecture provided a platform for meaningful dialogue and reflection on the status of women in society. Through the insightful discussions and reflections, participants were inspired to champion the cause of gender equality and work towards creating a more inclusive and equitable world for all.
</div>
    </li>
</ul>

      </div>
    )
  }
}
