import React from 'react'
import AboutUs from './AboutUs'
import Services from './Services'
import ContactContent from './contact/ContactContent'
import FieldWorkContent from './fieldwork/FieldWorkContent'
import NewFaceView from './NewFaceView'
import CaroselMultiView from './CaroselMultiView'
import {Row, Col, Button  } from "reactstrap";
import { Helmet } from 'react-helmet'
import VidyaJyotiBanner from './VidyaJyotiBanner'
import theologyBanner from '../assests/images/bibtheo.jpeg';
import '../css/style.css';
import GalleryMainView from './gallery/GalleryMainView'
import PrincipalMessage from './about/PrincipalMessage'
import TimelineVJ from '../pages/TimeLineVJ/TimelineVJ'
import Gallery from '../pages/Gallery'
import quote from "../assests/images/quote.jpg";
import AboutUsHomepageView from './about/AboutUsHomepageView'
import { Link } from 'react-router-dom'

export default function HomePage() {
  return (
    <div className='home-sec'>
    <Helmet>
      <title>Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <link rel="canonical" href="/" />
    </Helmet>

    <div className="App">
      <Row className='caroselrow pb-3' style={{marginLeft:"0px", marginRight:"0px"}}>
        <Col md={9} >
        <CaroselMultiView />
        <NewFaceView/>
        </Col>
        <Col  md={3} >
         <div className='card' style={{color:"#79031d",  overflow:"auto", width:"100%", height:"550px", padding:"5px", border:"3px solid brown"}}>
         <h5 className='p-2' style={{backgroundColor:"#79031d", borderRadius:"30%", color:"white"    }}>Theological Insights</h5>
      <img className='rounded m-2' src={quote} alt='quote' />
           <p  style={{lineHeight:"30px", fontFamily:"Satisfy", fontSize:"22px", textAlign:"justify"}}>
             The new wine in the realm of theology is the new courageous attitude that we bring to interpret theological categories and make it people centered.  Pope Francis urges the theologians in his Apostolic Letter, Ad theologiam promovendam, to understand that “promoting theology in the future cannot be limited to abstractly re-proposing formulas and schemes of the past” (Pope Francis, Apostolic Letter, “Motu Proprio” Ad theologiam promovendam, on 1st Nov. 2023), and he insists the need to place theology at the service of church and the world by proposing required modifications.
            </p>
         </div>
        </Col>
      </Row>
      {/*<CaroselView />*/}
     {/*} <FaceView />
      <NewFaceView />*/}
      <VidyaJyotiBanner />
      <div className='card container'>
        <div style={{height:"500px", overflow:"scroll"}}>
      <TimelineVJ />  
      </div>
      <Button variant="primary" type="submit" ><Link to="/about/vjtopstories"> <a href="#" >Read More...</a></Link></Button>
      </div>
      
      

      <div className="container-fluid project py-2 my-2">
            <div>
                <div className="text-center mx-auto wow fadeIn" data-wow-delay=".3s" style={{maxWidth: "600px"}}>
                    <h5 className="text-primary">VIDYAJYOTI COLLEGE</h5>
                    <h1 className='text-dark-main'>Principal's Desk</h1>
                </div>
      <AboutUsHomepageView />
      <Button style={{marginTop: "-7.5%", marginLeft:"15%"}} variant="primary" type="submit" ><Link to="/about/principalmessage"><a href="#" >Read More...</a></Link></Button>
      </div>
      </div>
      {/*<AboutUs/>
      <GalleryMainView />*/}
      <Gallery />

      <FieldWorkContent />
      <Services />

      {/*<Projects />
      <BlogView />
      <TeamDetails />
      <Testimonial />*/}
      <ContactContent />
    </div>
    </div>
  )
}
