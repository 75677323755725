import React, { useState, useEffect } from 'react';
import {Tabs, Tab, Breadcrumb, Row, Col} from 'react-bootstrap';
import ReactImageGallery from 'react-image-gallery';
import axios from 'axios';

const config = require('../../config.json');

export default function GalleryMainView() {
    const [tabValue, setTabValue]=useState("Pending");
    const images = [
      {
        original: "https://picsum.photos/id/1018/1000/600/",
        thumbnail: "https://picsum.photos/id/1018/250/150/",
        caption:"Annual Day Photos"
      },
      {
        original: "https://picsum.photos/id/1015/1000/600/",
        thumbnail: "https://picsum.photos/id/1015/250/150/",
        caption:"Convocation Photos"
      },
      {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
        caption:"Seminar Photos"  
      },
      {
        original: "https://picsum.photos/id/1019/1000/600/",
        thumbnail: "https://picsum.photos/id/1019/250/150/",
        caption:"Seminar Photos"  
      },
    ];

    const [galleryImages, setGalleryImages]=useState([]);
    const [loaded, setLoaded] = useState(false);

  useEffect(() => { fetchGalleryImages();
    const timer = setTimeout(() => setLoaded(true), 2000);
    return () => {
      clearTimeout(timer);
      setLoaded(false);
    }
  }, []);

  const fetchGalleryImages =async()=>{
    try{
       // console.log("Testing");
      //  const session = await Auth.currentSession();
      //  const access_token=session.idToken.jwtToken;
        const fileName='images/gallery/';
        const bucketName="images.vidyajyoticollege.in";
//      await axios.get(`${config.api.invokeUrl}/images/projects?fileName=${fileName}&bucketName=${bucketName}`,{
      await axios.get(`${config.api.invokeURL}/gallery?fileName=${fileName}&bucketName=${bucketName}`,{
        headers: {
         // Authorization: access_token,
          'x-api-key': config.api.key
        }}, 
    ).then((response)=>{
       // console.log(response.data);
        //setGalleryImages(response.data);
         processGalleryData(response.data);
        //setDispMsg(response.data.length + " - Images are found in your Gallery");
    }).catch((error)=>{
        console.log("Error:",error.message);
        //setDispMsg(error.message);
    })
}catch(error){
console.log(error.message);
    //setDispMsg(error.message);
}
  }

  const processGalleryData=(item)=>{
    const tempitem=item;
    var counter=0;
    for(const list in item){
     // console.log("counter : ", counter);
      var fileList=[]; 
    for(const i in item[list].fileName){
      var tempurl1="";
      const tempgallery={
        thumbnail:"",
        original:""
      };
      tempurl1="https://s3.ap-south-1.amazonaws.com/images.vidyajyoticollege.in/"+item[list].fileName[i];
      //console.log("tempurl : ", tempurl1);
      tempgallery.thumbnail=tempurl1;
      tempgallery.original=tempurl1;
      fileList.push(tempgallery);
    }
    counter++;
    tempitem[list].fileName=fileList
    //console.log("temp item : ", tempitem);
  }
  //console.log("tem[ : ", tempitem);
  setGalleryImages(tempitem);
  setLoaded(true);
  }

  return (
     <div className='container card'>
      <h1 className='text-dark-main'>Latest Events & Celebrations</h1>
   {loaded && galleryImages.length>0 && <Tabs
      defaultActiveKey="Pending"
      id="fill-tab-example"
      className=" mb-3"
      fill onSelect={(k) => setTabValue(k)}
    >
      <Tab eventKey="Pending" title={galleryImages[0].projectName}>
        <Row>
          <Col >
          <ReactImageGallery items={galleryImages[0].fileName} />
          </Col>
        {/*}  <Col className='gal-sec-view' md={3}>
          {galleryImages[0].fileName.map((item, index)=>
            <div key={index} className=' card'>
              <img src={item.thumbnail} alt="thumb1" />
            <h5 className="text-primary" style={{textAlign:"center"}}>{item.caption}</h5>
            </div>
          )}
        </Col>*/}
        </Row>
      </Tab>
      <Tab eventKey="Approved" title={galleryImages[1].projectName}>
      <Row>
          <Col >
      <ReactImageGallery items={galleryImages[1].fileName} />
          </Col>
        {/*}  <Col className='gal-sec-view' md={3}>
          {galleryImages[1].fileName.map((item, index)=>
            <div key={index} className=' card'>
              <img src={item.thumbnail} alt="thumb1" />
            <h5 className="text-primary" style={{textAlign:"center"}}>{item.caption}</h5>
            </div>            
          )}
        </Col>*/}
        </Row>
      </Tab>
      <Tab eventKey="Declined" title={galleryImages[2].projectName}>
      <Row>
          <Col md={9}>
      <ReactImageGallery items={galleryImages[2].fileName} />
          </Col>
       {/*}   <Col className='gal-sec-view' md={3}>
          {galleryImages[2].fileName.map((item, index)=>
            <div key={index} className=' card'>
              <img src={item.thumbnail} alt="thumb1" />
            <h5 className="text-primary" style={{textAlign:"center"}}>{item.caption}</h5>
            </div>
)}
</Col>*/}
        </Row>
      </Tab>
    </Tabs>}
    </div>
  )
}
